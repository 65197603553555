import { render, staticRenderFns } from "./EmojiSummary.vue?vue&type=template&id=b3c7432c&lang=html&"
import script from "./EmojiSummary.vue?vue&type=script&lang=js&"
export * from "./EmojiSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports